

































































































































































































































.viewAPI2 {
	// background: #ffffff;

	.bodyStyleDivStyleP {
		margin: 0.56rem 0 0.2rem 0;
	}

	.body1StyleInputStyle {
		border: 0;
		font-size: 0.28rem;
		width: 80%;
		line-height: 0.7rem;
	}
	.body1StyleInputStyle::-webkit-input-placeholder,
	.body1StyleInputStyle:-moz-placeholder,
	.body1StyleInputStyle::-moz-placeholder,
	.body1StyleInputStyle:-ms-input-placeholder,
	.body1StyleInputStyle::-ms-input-placeholder,
	.body1StyleInputStyle::placeholder {
		// color: #D6D6D6 !important;
		color: #FFFFFF !important;
	}

	.bottom2DivStyle {
		// border-top: 0.27rem solid RGBA(246, 248, 249, 1);
		// padding-top: 0.29rem;
		margin-bottom: 2.5rem;
	}

	/*.bodyStyleDivStyle {*/
	/*    border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
	/*    padding-bottom: 0.1rem;*/
	/*}*/

	.pc_in {
		position: relative;
		float: left;
		width: 6.7rem;
		height: 1.12rem;
		border: none;
	}

	.pc_in .divbox {
		display: flex;
		width: 100%;
		height: 1.12rem;
		overflow: hidden;
	}

	.pc_in .divbox span {
		flex: 1;
		height: 100%;
		border-bottom: #e6e8ed solid 1px;
		border-right: none;
		margin: 0 0.16rem;
	}

	.pc_in .divbox span.bl {
		border-color: #000;
	}

	.pc_in p {
		position: absolute;
		width: 100%;
		height: 1.12rem;
		line-height: 1.12rem;
		top: 0;
		left: 0;
		background: none;
		overflow: hidden;
	}

	.pc_in p input {
		float: left;
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
		margin-top: 0.2rem;
		letter-spacing: 0.84rem;
		background: none;
		text-indent: 0.4rem;
		font-size: 0.48rem;
	}

	.getCodeBtn {
		color: #90c4ff;
		width: 1.5rem;
		height: 0.5rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		background: none;
		border: 1px solid #90c4ff;
		border-radius: 4px;
		margin-top: 0.1rem;
		float: right;
	}

	.bottom2PStyle {
		font-size: 0.25rem;
		font-weight: 500;
		color: #999999;
		margin: 0 0.27rem 0.19rem;
	}

	.bottom3PStyle {
		color: #0f0f0f;
		margin: 0.3rem;
		font-size: 0.3rem;
	}

	.body1StyleSPan {
		color: #ff4b04;
	}

	.body1StylepFont {
		color: #666666;
		margin-top: 0.2rem;
		font-size: 0.28rem;
		margin-bottom: 0.5rem;
	}

	/* @import "../../assets/css/puliceStyle.css"; */

	.body1StyleInputStyle {
		border: 0;
		border-bottom: 0 dashed gray;
		width: 80%;
	}

	.bodyStyleDivStyleP {
		margin-top: 0.3rem;
		margin-bottom: 0.2rem;
	}

	.bodyStyleDivStyle {
		line-height: 0.4rem;
		border-bottom: 1px solid #f2f2f2;
		margin-bottom: 0.2rem;
		// padding-bottom: 0.1rem;
	}

	.body1Style {
		font-size: 0.28rem;
		padding: 0.3rem;
	}

	.bottom1 {
		margin-top: 0.3rem;
		padding-bottom: 0.3rem;
	}
	.help-btn {
		color: #29aae1;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 999;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;