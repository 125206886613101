.viewAPI2 {
  /*.bodyStyleDivStyle {*/
  /*    border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  /*    padding-bottom: 0.1rem;*/
  /*}*/
  /* @import "../../assets/css/puliceStyle.css"; */
}
.viewAPI2 .bodyStyleDivStyleP {
  margin: 0.56rem 0 0.2rem 0;
}
.viewAPI2 .body1StyleInputStyle {
  border: 0;
  font-size: 0.28rem;
  width: 80%;
  line-height: 0.7rem;
}
.viewAPI2 .body1StyleInputStyle::-webkit-input-placeholder,
.viewAPI2 .body1StyleInputStyle:-moz-placeholder,
.viewAPI2 .body1StyleInputStyle::-moz-placeholder,
.viewAPI2 .body1StyleInputStyle:-ms-input-placeholder,
.viewAPI2 .body1StyleInputStyle::-ms-input-placeholder,
.viewAPI2 .body1StyleInputStyle::placeholder {
  color: #FFFFFF !important;
}
.viewAPI2 .bottom2DivStyle {
  margin-bottom: 2.5rem;
}
.viewAPI2 .pc_in {
  position: relative;
  float: left;
  width: 6.7rem;
  height: 1.12rem;
  border: none;
}
.viewAPI2 .pc_in .divbox {
  display: flex;
  width: 100%;
  height: 1.12rem;
  overflow: hidden;
}
.viewAPI2 .pc_in .divbox span {
  flex: 1;
  height: 100%;
  border-bottom: #e6e8ed solid 1px;
  border-right: none;
  margin: 0 0.16rem;
}
.viewAPI2 .pc_in .divbox span.bl {
  border-color: #000;
}
.viewAPI2 .pc_in p {
  position: absolute;
  width: 100%;
  height: 1.12rem;
  line-height: 1.12rem;
  top: 0;
  left: 0;
  background: none;
  overflow: hidden;
}
.viewAPI2 .pc_in p input {
  float: left;
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  margin-top: 0.2rem;
  letter-spacing: 0.84rem;
  background: none;
  text-indent: 0.4rem;
  font-size: 0.48rem;
}
.viewAPI2 .getCodeBtn {
  color: #90c4ff;
  width: 1.5rem;
  height: 0.5rem;
  font-size: 0.24rem;
  line-height: 0.5rem;
  background: none;
  border: 1px solid #90c4ff;
  border-radius: 4px;
  margin-top: 0.1rem;
  float: right;
}
.viewAPI2 .bottom2PStyle {
  font-size: 0.25rem;
  font-weight: 500;
  color: #999999;
  margin: 0 0.27rem 0.19rem;
}
.viewAPI2 .bottom3PStyle {
  color: #0f0f0f;
  margin: 0.3rem;
  font-size: 0.3rem;
}
.viewAPI2 .body1StyleSPan {
  color: #ff4b04;
}
.viewAPI2 .body1StylepFont {
  color: #666666;
  margin-top: 0.2rem;
  font-size: 0.28rem;
  margin-bottom: 0.5rem;
}
.viewAPI2 .body1StyleInputStyle {
  border: 0;
  border-bottom: 0 dashed gray;
  width: 80%;
}
.viewAPI2 .bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}
.viewAPI2 .bodyStyleDivStyle {
  line-height: 0.4rem;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 0.2rem;
}
.viewAPI2 .body1Style {
  font-size: 0.28rem;
  padding: 0.3rem;
}
.viewAPI2 .bottom1 {
  margin-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.viewAPI2 .help-btn {
  color: #29aae1;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
